<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card class="">
          <md-card-header class="md-card-header-icon md-card-header-blue">
            <div class="card-icon">
              <md-icon>language</md-icon>
            </div>
            <h4 class="title">
              Sales per Country <small>(last 12 months)</small>
            </h4>
          </md-card-header>

          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-size-40">
                <md-table
                  v-model="sales"
                  table-header-color="orange"
                >
                  <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item }"
                  >
                    <md-table-cell>
                      <img
                        style="width: 25px"
                        :src="item.flag"
                        :alt="item.flag+'_flag'"
                      >
                    </md-table-cell>
                    <md-table-cell>
                      {{ item.name }}
                    </md-table-cell>
                    <md-table-cell>
                      {{ item.qty }}
                    </md-table-cell>
                    <md-table-cell>
                      {{ item.percentage.toFixed(2) }}%
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
              <div class="md-layout-item md-size-60">
                <async-world-map
                  v-if="loaded"
                  class="map"
                  :data="mapData"
                />
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <div class="md-layout">
      <div class="md-layout-item md-size-30 md-small-size-100">
        <md-card class="">
          <md-card-header class="md-card-header-icon md-card-header-blue">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">
              Generate Report
            </h4>
          </md-card-header>

          <md-card-content>
            <form-date
              v-model="report_from"
              name="from"
              label="From..."
            />
            <form-date
              v-model="report_to"
              name="to"
              label="To..."
            />
            <md-button
              class="md-info md-block"
              @click="downloadReport"
            >
              Download
            </md-button>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { FormDate } from '@/components/Inputs';
import AsyncWorldMap from '@/components/WorldMap/AsyncWorldMap';
import moment from 'moment';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    AsyncWorldMap,
    FormDate,
  },
  props: [],
  data() {
    return {
      report_from: moment().subtract(1, 'year'),
      report_to: moment(),
      sales: [],
      mapData: {},
      loaded: false,
    };
  },
  mounted() {
    this.getMapData();
  },
  methods: {
    getMapData() {
      this.request(this.$API.SALES_PER_COUNTRY, 'get', null, ({ data }) => {
        const aux = {};
        data.forEach((x) => {
          if (x.qty !== null) aux[x.alpha_code] = parseFloat(x.qty);
        });
        this.mapData = aux;
        this.loaded = true;
        this.sales = data.slice(0, 6);
      });
    },
    downloadReport() {
      this.request(this.$API.SALES_PER_COUNTRY, 'download', {
        download: true,
        from: this.report_from,
        to: this.report_to,
      }, ({ data }) => {
        const filename = `Sales_por_country_report_FROM_${this.report_from.format('DD-MM-YYYY')}_TO_${this.report_to.format('DD-MM-YYYY')}.csv`;
        downloadFile(data, filename);
      });
    },
  },
};
</script>

<style scoped>
</style>
